import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.entries.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import useDateTimeFormatters from "~/functions/useDateTimeFormatters"; /**
                                                                       *
                                                                       * Custom composition function for handling Morningstar financial data.
                                                                       * @param {Object} props - Props object.
                                                                       * @param {Ref} props.period - Reactive reference to the selected financial period.
                                                                       * @param {Ref} props.activeStatement - Reactive reference to the active financial statement.
                                                                       * @returns {Object} - An object containing the following computed properties:
                                                                       *   - morningstarFinancials: The financial data for the selected period.
                                                                       *   - morningstarStatement: The financial statement object for the active statement.
                                                                       *   - availableMorningstarDates: An array of formatted dates available for the selected period.
                                                                       */
var MorningstarUtils = function MorningstarUtils() {
  var props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store;
  var _useDateTimeFormatter = useDateTimeFormatters(),
    utcEpochToShortDate = _useDateTimeFormatter.utcEpochToShortDate,
    utcEpochToTwoDigitYear = _useDateTimeFormatter.utcEpochToTwoDigitYear;
  var morningstarFinancials = computed(function () {
    var finData = $store.state.morningstar.financials;
    var period = props.period.value;
    if (finData[period]) {
      return finData[period];
    } else {
      return {};
    }
  });
  var morningstarStatement = computed(function () {
    var _props$activeStatemen, _finDataForPeriod$fin, _finDataForPeriod$fin2;
    var finDataForPeriod = morningstarFinancials === null || morningstarFinancials === void 0 ? void 0 : morningstarFinancials.value;
    var activeStatement = (_props$activeStatemen = props.activeStatement) === null || _props$activeStatemen === void 0 ? void 0 : _props$activeStatemen.value;
    var morningstarMetrics = finDataForPeriod === null || finDataForPeriod === void 0 || (_finDataForPeriod$fin = finDataForPeriod.financials) === null || _finDataForPeriod$fin === void 0 ? void 0 : _finDataForPeriod$fin[activeStatement]; // Handle Segments
    if (activeStatement === 4) {
      return {};
    }
    var financialLineItems = finDataForPeriod === null || finDataForPeriod === void 0 || (_finDataForPeriod$fin2 = finDataForPeriod.financialsLineItems) === null || _finDataForPeriod$fin2 === void 0 ? void 0 : _finDataForPeriod$fin2[activeStatement]; // Handle Morningstar Ratios
    if (activeStatement === 3) {
      return {
        statement: financialLineItems === null || financialLineItems === void 0 ? void 0 : financialLineItems.statement,
        keys: financialLineItems === null || financialLineItems === void 0 ? void 0 : financialLineItems.keys.map(function (metricKey) {
          var secondMetric = morningstarMetrics.find(function (secondMetricKey) {
            var isSameKey = secondMetricKey.tikrKey === metricKey.tikrKey;
            return isSameKey;
          }) || {};
          return _objectSpread(_objectSpread({}, metricKey), secondMetric);
        })
      };
    }
    var morningstarMetricsValues = Object.values(morningstarMetrics);
    return {
      statement: financialLineItems === null || financialLineItems === void 0 ? void 0 : financialLineItems.statement,
      keys: financialLineItems === null || financialLineItems === void 0 ? void 0 : financialLineItems.keys.map(function (metricKey) {
        var secondMetric = morningstarMetricsValues.find(function (secondMetricKey) {
          var isSameKey = secondMetricKey.tikrKey === metricKey.tikrKey;
          var isSameName = secondMetricKey.name === metricKey.name;
          return isSameKey && isSameName;
        }) || {};
        return _objectSpread(_objectSpread({}, metricKey), secondMetric);
      })
    };
  });
  var morningstarStatements = computed(function () {
    var finDataForPeriod = morningstarFinancials === null || morningstarFinancials === void 0 ? void 0 : morningstarFinancials.value;
    if (!(finDataForPeriod !== null && finDataForPeriod !== void 0 && finDataForPeriod.financials)) {
      return [];
    }
    return Object.entries(finDataForPeriod.financials).map(function (_ref) {
      var _finDataForPeriod$fin3;
      var _ref2 = _slicedToArray(_ref, 2),
        statementKey = _ref2[0],
        morningstarMetrics = _ref2[1];
      var financialLineItems = finDataForPeriod === null || finDataForPeriod === void 0 || (_finDataForPeriod$fin3 = finDataForPeriod.financialsLineItems) === null || _finDataForPeriod$fin3 === void 0 ? void 0 : _finDataForPeriod$fin3[statementKey]; // Handle Morningstar Ratios
      if (statementKey === "3") {
        return financialLineItems === null || financialLineItems === void 0 ? void 0 : financialLineItems.keys.map(function (metricKey) {
          var secondMetric = morningstarMetrics.find(function (secondMetricKey) {
            return secondMetricKey.tikrKey === metricKey.tikrKey;
          }) || {};
          return _objectSpread(_objectSpread({}, metricKey), secondMetric);
        });
      }
      var morningstarMetricsValues = Object.values(morningstarMetrics);
      return financialLineItems === null || financialLineItems === void 0 ? void 0 : financialLineItems.keys.map(function (metricKey) {
        var secondMetric = morningstarMetricsValues.find(function (secondMetricKey) {
          return secondMetricKey.tikrKey === metricKey.tikrKey && secondMetricKey.name === metricKey.name;
        }) || {};
        return _objectSpread(_objectSpread({}, metricKey), secondMetric);
      });
    });
  });
  var morningstarSegments = computed(function () {
    var finDataForPeriod = morningstarFinancials.value;
    var morningstarSegmentObj = {
      template: finDataForPeriod === null || finDataForPeriod === void 0 ? void 0 : finDataForPeriod.segTemplate,
      data: finDataForPeriod === null || finDataForPeriod === void 0 ? void 0 : finDataForPeriod.segData
    };
    return morningstarSegmentObj;
  });
  var availableMorningstarDates = computed(function () {
    var finDataForPeriod = morningstarFinancials.value;
    var dates = [];
    if (finDataForPeriod.dates) {
      dates = finDataForPeriod.dates;
    }
    var formattedDates = dates.map(function (d) {
      var dateObj = _objectSpread({}, d);
      dateObj.text = dateObj.isLTM ? "LTM" : utcEpochToShortDate.value.format(new Date(dateObj.dateEpoch));
      dateObj.annualLabel = "'".concat(utcEpochToTwoDigitYear.value.format(new Date(dateObj.dateEpoch)));
      dateObj.quarterLabel = "".concat(dateObj.quarterStr.substring(1), "Q").concat(utcEpochToTwoDigitYear.value.format(new Date(dateObj.dateEpoch)));
      return dateObj;
    });
    return formattedDates;
  });
  return {
    morningstarFinancials: morningstarFinancials,
    morningstarStatement: morningstarStatement,
    morningstarStatements: morningstarStatements,
    morningstarSegments: morningstarSegments,
    availableMorningstarDates: availableMorningstarDates
  };
};
export default MorningstarUtils;